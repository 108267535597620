// Source: https://stackoverflow.com/a/40388120
<script>
export default {
  name: 'rowlo-title',
  props: ['title'],
  created() {
    document.title = this.title
  },
  watch: {
    title() {
      // only used when the title changes after page load
      document.title = this.title
    },
  },
  render() {
    return ''
  },
}
</script>
