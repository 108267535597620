import Vue from 'vue'
import { i18n } from '@/plugins/i18n'
import VueRouter from 'vue-router'
import RowloHome from '@/views/rowlo-home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Robert Wloch',
    component: RowloHome,
    props: route => ({ query: route.query.language }),
  },
  // { path: '/sta-boqwi' },
  // { path: '/honey' },
  // {
  //   path: '/honey/',
  //   name: 'Honig',
  //   // component: RowloHoney
  //   component: () =>
  //     import(/* webpackChunkName: "honey" */ '@/views/rowlo-honey.vue'),
  //   props: route => ({ query: route.query.language }),
  // },
  // {
  //   path: '/honig/',
  //   redirect: '/honey',
  // },
  {
    path: '/poems/',
    name: 'Gedichte',
    // component: RowloPoems
    component: () =>
      import(/* webpackChunkName: "poems" */ '@/views/rowlo-poems.vue'),
    props: route => ({ query: route.query.language }),
  },
  {
    path: '/poem/:poemId/',
    name: 'Gedicht',
    // component: RowloPoem
    component: () =>
      import(/* webpackChunkName: "poem" */ '@/views/rowlo-poem.vue'),
    props: route => ({ query: route.query.language }),
  },
  {
    path: '/privacypolicy/',
    name: 'rowlo-privacy-policy',
    // component: RowloPrivacyPolicy
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ '@/views/rowlo-privacy-policy.vue'
      ),
    props: route => ({ query: route.query.language }),
  },
  {
    path: '/imprint/',
    name: 'rowlo-imprint',
    // component: RowloImprint
    component: () =>
      import(/* webpackChunkName: "imprint" */ '@/views/rowlo-imprint.vue'),
    props: route => ({ query: route.query.language }),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  beforeEnter: (to, _from, next) => {
    const lang = to.params.language
    if (!['de', 'en'].includes(lang)) return next('en')
    if (i18n.locale === lang) {
      i18n.locale = lang
    }
    return next()
  },
})

export default router
