<template>
  <v-app id="app" class="ma-0 pa-0">
    <rowlo-title :title="$t('siteTitle')"></rowlo-title>
    <v-main class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <rowlo-header :menuLinks="menuLinks" class="ma-0 pa-0"></rowlo-header>
        </v-col>
        <v-container class="px-6 py-4" fluid>
          <router-view />
        </v-container>
        <v-col cols="12" class="ma-0 pa-0">
          <rowlo-footer :legalLinks="legalLinks"></rowlo-footer>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import RowloHeader from '@/components/rowlo-header'
import RowloFooter from '@/components/rowlo-footer'

export default {
  name: 'app',
  components: {
    RowloHeader,
    RowloFooter,
  },
  data: () => ({
    menuLinks: [
      {
        title: 'poems',
        url: '/poems/',
      },
      {
        title: 'privacypolicy',
        url: '/privacypolicy/',
      },
      {
        title: 'imprint',
        url: '/imprint/',
      },
    ],
    legalLinks: [
      {
        title: 'privacypolicy',
        url: '/privacypolicy/',
      },
      {
        title: 'imprint',
        url: '/imprint/',
      },
    ],
  }),
}
</script>

<style>
.no-padding {
  padding: 0px;
}
.padding-10 {
  padding: 10px;
}
.padding-5 {
  padding: 5px;
}
.no-margin {
  margin: 0px;
}
.fs-14 {
  font-size: 14px;
  overflow-wrap: inherit;
}
</style>
