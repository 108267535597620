<template>
  <v-container id="rowlo-header" class="pa-0" fluid color="primary">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-toolbar id="rowlo-header" color="#f5f5f5" class="ma-0 pa-0">
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
            </template>
            <v-list>
              <v-list-item
                v-for="(menuLink, i) in menuLinks"
                :key="i"
                :to="menuLink.url + routes.language"
                :ripple="false"
              >
                <v-list-item-title>{{ $t(menuLink.title) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <router-link
            :to="routes.home"
            :ripple="false"
            class="no-text-decoration"
          >
            <v-toolbar-title :class="useSmallFont">
              {{ $t('siteTitle') }}
            </v-toolbar-title>
          </router-link>

          <v-spacer></v-spacer>
          <span
            class="text-xs-center"
            v-if="canSearchPoems && $breakpoint.mdAndUp"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <!-- 
                  v-validate="{
                    required: false,
                    regex: /([0-9a-zA-ZäöüÄÖÜß\\-_ :]*)$/,
                  }"
                  data-vv-name="searchPattern"
                  :error-messages="errors.collect('searchPattern')"
                -->
                <v-text-field
                  v-on="on"
                  v-model="searchPattern"
                  flat
                  solo
                  class="hidden-sm-and-down ma-0 pa-0"
                  :label="$t('searchLabel')"
                  type="text"
                  hide-details
                  @change="searchPoem"
                  prepend-inner-icon="$vuetify.icons.search"
                  @click:prepend-inner="searchPoem"
                  clearable
                  clear-icon="$vuetify.icons.clear"
                  @click:clear="clearSearch"
                ></v-text-field>
              </template>
              <span>{{ $t('searchTooltip') }}</span>
            </v-tooltip>
          </span>

          <!-- <v-btn icon>
            <v-icon>$vuetify.icons.apps</v-icon>
          </v-btn> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
                target="_blank"
                href="https://www.instagram.com/robert.wloch/"
              >
                <v-icon>$vuetify.icons.instagram</v-icon>
              </v-btn>
            </template>
            <span>/robert.wloch</span>
          </v-tooltip>
          <rowlo-language-selector></rowlo-language-selector>

          <!-- <v-btn icon>
            <v-icon>$vuetify.icons.more</v-icon>
          </v-btn> -->
        </v-toolbar>
      </v-col>
      <v-col
        cols="12"
        v-if="$breakpoint.smAndDown && canSearchPoems"
        class="pa-2"
      >
        <span class="text-xs-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <!-- 
                v-validate="{
                  required: false,
                  regex: /([0-9a-zA-ZäöüÄÖÜß\\-_ :]*)$/,
                }" 
                data-vv-name="searchPattern"
                :error-messages="errors.collect('searchPattern')"
              -->
              <v-text-field
                v-on="on"
                v-model="searchPattern"
                flat
                outlined
                class="ma-0 pa-0"
                :label="$t('searchLabel')"
                type="text"
                hide-details
                @change="searchPoem"
                prepend-inner-icon="$vuetify.icons.search"
                @click:prepend-inner="searchPoem"
                clearable
                clear-icon="$vuetify.icons.clear"
                @click:clear="clearSearch"
              ></v-text-field>
            </template>
            <span>{{ $t('searchTooltip') }}</span>
          </v-tooltip>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { eventBus } from '@/main'
import breakpoint from 'vue-md-breakpoint'
import RowloLanguageSelector from '@/components/rowlo-language-selector'

export default {
  name: 'rowlo-header',
  mixins: [breakpoint],
  props: ['menuLinks'],
  components: { RowloLanguageSelector },
  data() {
    return {
      searchPattern: '',
      dictionary: {
        custom: {
          age: {
            required: () =>
              'Only characters, numbers, dash, underscore and space allowed',
          },
        },
      },
    }
  },
  // veeValidate: {
  //   validator: 'new',
  // },
  computed: {
    routes() {
      let language = eventBus.getLanguageLoevingerCycleQueryForRoute()
      return {
        language: language,
        home: '/' + language,
      }
    },
    canSearchPoems() {
      return this.$route.path.startsWith('/poems/')
    },
    useSmallFont() {
      return {
        'small-font': this.$breakpoint.smAndDown,
      }
    },
  },
  methods: {
    // pushRouteAsync (route) {
    //   return new Promise((resolve, reject) => {
    //     if (this.$route !== route) {
    //       this.$router.push(route, resolve, reject)
    //     }
    //   })
    // },
    async searchPoem() {
      // await this.pushRouteAsync('/poems')
      let pattern =
        this.searchPattern === null || this.searchPattern === ''
          ? ''
          : this.searchPattern.toLowerCase()
      eventBus.$emit('updateSearchPattern', pattern)
    },
    clearSearch() {
      this.searchPattern = ''
      let pattern =
        this.searchPattern === null || this.searchPattern === ''
          ? ''
          : this.searchPattern.toLowerCase()
      eventBus.$emit('updateSearchPattern', pattern)
    },
    listenOnInitializeSearchPattern() {
      eventBus.$on('initializeSearchPattern', searchPattern => {
        this.searchPattern = searchPattern
        this.searchPoem()
      })
    },
  },
  created() {
    this.listenOnInitializeSearchPattern()
  },
}
</script>

<style scoped>
.small-font {
  font-size: small;
}
a.no-text-decoration {
  text-decoration: none;
}
</style>
