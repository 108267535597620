import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { CUSTOM_ICONS } from '@/plugins/font-awesome'
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  // customVariables: ['@/assets/variables.scss'],
  treeShake: true,
  theme: {
    themes: {
      light: {
        primary: '#333333',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'faSvg',
    values: CUSTOM_ICONS,
  },
})
