import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faBars as fasBars,
  faBookOpen as fasBookOpen,
  faChevronLeft as fasChevronLeft,
  faCode as fasCode,
  faCommentAlt as fasCommonAlt,
  faEllipsisV as fasEllipsisV,
  faEraser as fasEraser,
  faExclamationTriangle as fasExclamationTriangle,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faInfoCircle as fasInfoCircle,
  faSearch as fasSearch,
  faShareSquare as fasShareSquare,
  faSyncAlt as fasSyncAlt,
  faTag as fasTag,
  faTh as fasTh,
  faTimesCircle as fasTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
  faInstagram as fabInstagram,
  faTwitter as fabTwitter,
} from '@fortawesome/free-brands-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)

library.add(
  fasBars,
  fasBookOpen,
  fasChevronLeft,
  fasCode,
  fasCommonAlt,
  fasEllipsisV,
  fasEraser,
  fasExclamationTriangle,
  fasEye,
  fasEyeSlash,
  fasInfoCircle,
  fabInstagram,
  fasSearch,
  fasShareSquare,
  fasSyncAlt,
  fasTag,
  fasTh,
  fasTimesCircle,
  fabTwitter
)

// Usage: <v-icon>$vuetify.icons.code</v-icon>
// Usage: <v-icon>$code</v-icon>
const CUSTOM_ICONS = {
  menu: { component: FontAwesomeIcon, props: { icon: ['fas', 'bars'] } },
  code: { component: FontAwesomeIcon, props: { icon: ['fas', 'code'] } },
  search: { component: FontAwesomeIcon, props: { icon: ['fas', 'search'] } },
  apps: { component: FontAwesomeIcon, props: { icon: ['fas', 'th'] } },
  refresh: { component: FontAwesomeIcon, props: { icon: ['fas', 'sync-alt'] } },
  more: { component: FontAwesomeIcon, props: { icon: ['fas', 'ellipsis-v'] } },
  tag: { component: FontAwesomeIcon, props: { icon: ['fas', 'tag'] } },
  clear: { component: FontAwesomeIcon, props: { icon: ['fas', 'eraser'] } },
  poem: { component: FontAwesomeIcon, props: { icon: ['fas', 'book-open'] } },
  twitter: { component: FontAwesomeIcon, props: { icon: ['fab', 'twitter'] } },
  instagram: {
    component: FontAwesomeIcon,
    props: { icon: ['fab', 'instagram'] },
  },
  view: { component: FontAwesomeIcon, props: { icon: ['fas', 'eye'] } },
  back: {
    component: FontAwesomeIcon,
    props: { icon: ['fas', 'chevron-left'] },
  },
  tooltip: {
    component: FontAwesomeIcon,
    props: { icon: ['fas', 'comment-alt'] },
  },
  propagate: {
    component: FontAwesomeIcon,
    props: { icon: ['fas', 'share-square'] },
  },
  visibility: { component: FontAwesomeIcon, props: { icon: ['fas', 'eye'] } },
  visibility_off: {
    component: FontAwesomeIcon,
    props: { icon: ['fas', 'eye-slash'] },
  },
  info: { component: FontAwesomeIcon, props: { icon: ['fas', 'info-circle'] } },
  warning: {
    component: FontAwesomeIcon,
    props: { icon: ['fas', 'excalamtion-triangle'] },
  },
  error: {
    component: FontAwesomeIcon,
    props: { icon: ['fas', 'times-circle'] },
  },
}

export { CUSTOM_ICONS }
