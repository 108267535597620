<template>
  <v-menu id="rowlo-language-selector" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <img
          :src="'/images/' + flagForCurrentLocale + '.png'"
          width="20px"
          height="12px"
        />
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in languages"
        :key="index"
        @click="setLocale(item.locale)"
      >
        <v-list-item-avatar>
          <img :src="'/images/' + item.name + '.png'" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'rowlo-language-selector',
  data: () => ({
    languages: [
      { name: 'Deutsch', locale: 'de' },
      { name: 'English', locale: 'en' },
    ],
  }),
  computed: {
    flagForCurrentLocale() {
      let lang = this.languages.find(l => l.locale === this.$i18n.locale)
      return lang.name
    },
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
      // this.$route.params.language = locale
    },
  },
}
</script>
