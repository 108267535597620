<template>
  <v-tooltip :id="'rowlo-built-with-' + builtWith.title.toLowerCase()" top>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        rounded
        class="padding-left-right-5 margin-left-right-5"
        v-on="on"
        target="_blank"
        :href="builtWith.url"
      >
        <v-avatar tile size="21px">
          <v-img :src="'/images/' + builtWith.icon"></v-img>
        </v-avatar>
      </v-btn>
    </template>
    <span>{{ builtWith.title }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'rowlo-built-with',
  props: ['builtWith'],
  computed: {
    imageRoot() {
      let prefix = '/'
      if (this.$route !== '/') {
        prefix = '../'
      }
      return prefix + 'images/'
    },
  },
}
</script>

<style scoped>
.margin-left-right-5 {
  margin: 0px 5px 0px 5px;
}
.padding-left-right-5 {
  padding: 0px 5px 0px 5px;
}
</style>
