//import './css/colors.css'
// import 'vuetify/src/stylus/app.styl' // Ensure you are using stylus-loader
import Vue from 'vue'
import '@/plugins/axios'
// import App from './App.vue'
import App from '@/rowlo-app.vue'
// import VeeValidate from 'vee-validate'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import RowloTitle from '@/components/rowlo-title.vue'
import { i18n } from '@/plugins/i18n'

// Vue.use(VeeValidate)

Vue.config.productionTip = false

// https://github.com/vuetifyjs/vuetify/issues/9999#issuecomment-569932219
const ignoreWarnMessage =
  'The .native modifier for v-on is only valid on components but it was used on <svg>.'
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function(msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null
    vm = null
    trace = null
  }
}

Vue.component('rowlo-title', RowloTitle)

export const eventBus = new Vue({
  i18n,
  data: () => ({
    poems: [],
    targetLength: 0,
    loevingerInRoute: false,
    cycleInRoute: false,
  }),
  methods: {
    clearPoems() {
      this.poems = []
      this.$emit('poemsWasCleared')
    },
    awaitPoems(length) {
      this.targetLength = length
    },
    addPoem(poem) {
      this.poems.push(poem)
      if (this.poems.length === this.targetLength) {
        this.$emit('poemWasAdded', this.poems)
      }
    },
    addPoemSummary(poem) {
      this.poems.push(poem)
      if (this.poems.length === this.targetLength) {
        this.$emit('poemWasAdded', this.poems)
      }
    },
    updatePoem(poem) {
      let filteredPoems = this.poems.filter(p => {
        return p.id === poem.id && !p.image
      })
      if (filteredPoems && filteredPoems[0]) {
        filteredPoems[0].verses = poem.verses
        this.$emit('poemWasAdded', this.poems)
      }
    },
    getPoems() {
      return this.poems
    },
    updateI18nFromRoute(q) {
      if (q && q.language) {
        let lang = ['de', 'en'].includes(q.language) ? q.language : 'de'
        this.$i18n.locale = lang
      }
      if (q && q.loevinger) {
        this.loevingerInRoute = ['show'].includes(q.loevinger)
      }
      if (q && q.cycle) {
        this.cycleInRoute = ['true'].includes(q.cycle)
      }
    },
    updateSearchFromRoute(q) {
      if (q && q.search) {
        let pattern = decodeURIComponent(q.search.replace(/\+/g, '%20'))
        let isValidSearch = /([0-9a-zA-ZäöüÄÖÜß\\-_ :]*)$/.test(pattern)
        if (isValidSearch) {
          this.$emit('updateSearchPattern', pattern)
        }
      }
    },
    getLanguageQueryForRoute() {
      return '?language=' + this.$i18n.locale
    },
    getLanguageLoevingerCycleQueryForRoute() {
      let loevinger = this.loevingerInRoute ? '&loevinger=show' : ''
      let cycle = this.cycleInRoute ? '&cycle=true' : ''
      return '?language=' + this.$i18n.locale + loevinger + cycle
    },
  },
})

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
