<template>
  <v-card id="rowlo-home-tile-honey" style="height: 472px">
    <a :href="routes.honey" class="clickable no-text-decoration">
      <v-img src="/images/api-01.png" aspect-ratio="1.77"></v-img>
    </a>
    <a :href="routes.honey" class="clickable no-text-decoration">
      <v-card-title primary-title class="pt-6 pb-0">
        <p class="content-card-title">{{ $t('honey') }}</p>
      </v-card-title>
      <v-card-text>
        <p class="fs-14">{{ $t('honeyCardText') }}</p>
      </v-card-text>
    </a>
  </v-card>
</template>

<script>
import { eventBus } from '@/main.js'

export default {
  name: 'rowlo-home-tile-honey',
  props: ['language'],
  computed: {
    routes() {
      let language = eventBus.getLanguageQueryForRoute()
      return {
        honey: '/honey/index.html#/' + language,
      }
    },
  },
  created() {
    eventBus.updateI18nFromRoute(this.$route.query)
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.content-card-title {
  font-family: 'Arizonia';
  font-size: 32px;
  word-break: break-word;
}
a.no-text-decoration {
  text-decoration: none;
}
</style>
