<template>
  <v-row id="rowlo-home" class="ma-0 pa-0">
    <rowlo-title :title="$t('siteTitle')"></rowlo-title>
    <v-col cols="12" lg="6" offset-lg="3" sm="8" offset-sm="2" class="pa-0">
      <v-row class="ma-0 pa-0" justify="space-between">
        <v-col
          v-for="(tile, k) in homeTiles"
          :key="k"
          lg="6"
          :offset-lg="homeTiles.length === 1 ? 3 : 0"
          md="8"
          offset-md="2"
          sm="10"
          offset-sm="1"
          cols="12"
          offset="0"
          class="pa-0"
        >
          <component :is="tile.component" class="mx-3 my-2"></component>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import RowloHomeTileHoney from '@/components/rowlo-home-tile-honey'
import RowloHomeTilePoem from '@/components/rowlo-home-tile-poem'
import RowloHomeTileStaBoqwi from '@/components/rowlo-home-tile-sta-boqwi'

export default {
  name: 'rowlo-home',
  components: {
    'rowlo-home-tile-honey': RowloHomeTileHoney,
    'rowlo-home-tile-poem': RowloHomeTilePoem,
    'rowlo-home-tile-sta-boqwi': RowloHomeTileStaBoqwi,
  },
  data() {
    return {
      homeTiles: [
        {
          component: RowloHomeTilePoem.name,
        },
        {
          component: RowloHomeTileHoney.name,
        },
        {
          component: RowloHomeTileStaBoqwi.name,
        },
      ],
    }
  },
}
</script>
