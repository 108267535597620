<template>
  <v-card id="rowlo-home-tile-poem" style="height: 472px">
    <router-link :to="routes.poems" class="clickable no-text-decoration">
      <v-img src="/images/poetry.jpg" aspect-ratio="1.77"></v-img>
    </router-link>
    <router-link :to="routes.poems" class="clickable no-text-decoration">
      <v-card-title primary-title class="pt-6 pb-0">
        <p class="content-card-title">{{ $t('poems') }}</p>
      </v-card-title>
      <v-card-text>
        <p class="fs-14">{{ $t('poetryCardText') }}</p>
        <p v-if="visualizeLoevingerStage" class="ma-0 fs-14">
          {{ $t('poetryCardTextLoevinger') }}
        </p>
      </v-card-text>
    </router-link>
    <p class="ma-0 pa-0 px-4 pb-4 fs-14" v-if="visualizeLoevingerStage">
      <a
        :href="$t('loevingerUrl')"
        target="_blank"
        :alt="$t('loevingerUrlText')"
        >{{ $t('loevingerUrlText') }}</a
      >
    </p>
  </v-card>
</template>

<script>
import { eventBus } from '@/main.js'

export default {
  name: 'rowlo-home-tile-poem',
  props: ['language'],
  computed: {
    routes() {
      let language = eventBus.getLanguageLoevingerCycleQueryForRoute()
      return {
        poems: '/poems/' + language,
      }
    },
    visualizeLoevingerStage() {
      return eventBus.loevingerInRoute
    },
  },
  created() {
    eventBus.updateI18nFromRoute(this.$route.query)
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.content-card-title {
  font-family: 'Arizonia';
  font-size: 32px;
  word-break: break-word;
}
a.no-text-decoration {
  text-decoration: none;
}
</style>
