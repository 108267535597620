<template>
  <v-footer id="rowlo-footer" height="auto" class="ma-0 pa-0">
    <v-container fluid :class="useSmallFont" class="ma-0 pa-0">
      <v-row align="center" justify="center" class="ma-0 pa-0">
        <v-col cols="auto" class="ma-0 pa-0 fs-14">
          <router-link
            :to="routes.home"
            :ripple="false"
            class="no-text-decoration padding-left-right-5 margin-left-right-5"
          >
            &copy; {{ new Date().getFullYear() }} — Robert Wloch
          </router-link>
          <br v-if="$breakpoint.xsOnly" />
          <router-link
            v-for="(legalLink, i) in legalLinks"
            :key="i"
            :to="legalLink.url + routes.language"
            :ripple="false"
            class="no-text-decoration padding-left-right-5 margin-left-right-5"
          >
            {{ $t(legalLink.title) }}
          </router-link>
          <br v-if="$breakpoint.smAndDown" />
          <span
            class="grey--text padding-left-right-5 margin-left-right-5"
            :class="useSmallFont"
            >Built with &amp; thanks to:
          </span>
          <rowlo-built-with
            v-for="thanks in thanksBuiltWithLinks"
            :key="thanks.title"
            :builtWith="thanks"
          ></rowlo-built-with>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { eventBus } from '@/main.js'
import breakpoint from 'vue-md-breakpoint'
import RowloBuiltWith from '@/components/rowlo-built-with'

export default {
  name: 'rowlo-footer',
  mixins: [breakpoint],
  props: ['legalLinks'],
  components: {
    RowloBuiltWith,
  },
  data: () => ({
    thanksBuiltWithLinks: [
      {
        title: 'Vue.js',
        url: 'https://vuejs.org/',
        icon: 'vue.png',
      },
      {
        title: 'Vuetify',
        url: 'https://vuetifyjs.com/',
        icon: 'vuetify.png',
      },
      // {
      //   title: 'Vue.jsVeeValidate',
      //   url: 'https://baianat.github.io/vee-validate/',
      //   icon: 'vee-validate.png',
      // },
      {
        title: 'Font Awesome',
        url: 'https://fontawesome.com/',
        icon: 'font_awesome.png',
      },
    ],
  }),
  computed: {
    routes() {
      let language = eventBus.getLanguageQueryForRoute()
      return {
        language: language,
        home: '/' + language,
      }
    },
    useSmallFont() {
      return {
        'small-font': this.$breakpoint.smAndDown,
      }
    },
  },
}
</script>

<style scoped>
.margin-left-right-5 {
  margin: 0px 5px 0px 5px;
}
.padding-left-right-5 {
  padding: 0px 5px 0px 5px;
}
.small-font {
  font-size: small;
}
a.no-text-decoration {
  text-decoration: none;
}
</style>
